import { useLocation } from '@reach/router';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

/**
 * Custom hook to return GuestName
 * @return {string}
 */
function useGuestCode() {
  const location = useLocation();
  const code = getQueryValue(location, 'code');
  const finalCode = decodeURIComponent(code);

  return finalCode;
}

export default useGuestCode;
